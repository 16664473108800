<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-radio-group
            v-model="vm.riskAssessmentUndertaken"
            label="Have you undertaken a risk assessment in response to this incident?"
            v-validate="'required'"
            data-vv-as="Have you undertaken a risk assessment in response to this incident"
            name="riskAssessmentUndertaken"
            :error-messages="errors.collect('riskAssessmentUndertaken')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="In Progress" value="inProgress"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="vm.riskAssessmentUndertaken == true">
          <v-col cols="6">
            <h6>If yes, date risk assessment was complete</h6>
            <v-date-picker
              v-model="vm.riskAssessmentCompletedDate"
              no-title="true"
              v-validate="'required'"
              data-vv-as="Risk assessment completed date"
              name="riskAssessmentCompletedDate"
              :error-messages="errors.collect('riskAssessmentCompletedDate')"
            ></v-date-picker>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="vm.detailsOfRiskAssessment"
              label="Details of risk assessment"
              v-validate="'required'"
              data-vv-as="detailsOfRiskAssessment"
              name="detailsOfRiskAssessment"
              :error-messages="errors.collect('detailsOfRiskAssessment')"
            ></v-textarea>
          </v-col>
        </template>
        <template v-if="vm.riskAssessmentUndertaken == false">
          <v-col cols="12">
            <v-textarea
              v-model="vm.reasonNoRiskAssessment"
              label="If no risk assessment has been undertaken, what is the reason for not undertaking a risk assessment"
              v-validate="'required'"
              data-vv-as="reasonNoRiskAssessment"
              name="reasonNoRiskAssessment"
              :error-messages="errors.collect('reasonNoRiskAssessment')"
            ></v-textarea>
          </v-col>
        </template>
        <template v-if="vm.riskAssessmentUndertaken == 'inProgress'">
          <v-col cols="12">
            <h4>If you have a risk assessment in progress, when was it started?</h4>
            <v-date-picker
              v-model="vm.riskAssessmentInProgressStartDate"
              no-title="true"
              v-validate="'required'"
              data-vv-as="Risk assessment in progress start date"
              name="riskAssessmentInProgressStartDate"
              :error-messages="errors.collect('riskAssessmentInProgressStartDate')"
            ></v-date-picker>
          </v-col>
          <v-col cols="12">
            <h4>When do you expect it to be finished?</h4>
            <v-date-picker
              v-model="vm.riskAssessmentInProgressExpectedFinishDate"
              no-title="true"
              v-validate="'required'"
              data-vv-as="Risk assessment in progress expected finish date"
              name="riskAssessmentInProgressExpectedFinishDate"
              :error-messages="errors.collect('riskAssessmentInProgressExpectedFinishDate')"
            ></v-date-picker>
          </v-col>
        </template>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "risk-assessment",

  data: () => ({
    vm: []
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.riskAssessment == undefined
          ? {}
          : this.currentIncident.riskAssessment;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.riskAssessment = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

